import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Goback from "../assets/image/GoBack.svg";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BillingAddInputField from "./BillingAddInputField";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "react-query";
import {
  getCustomerRegister,
  getLastInvoice,
  getProductDetail,
} from "../api/GlobalApi";
import { Autocomplete, TextField } from "@mui/material";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import axios from "axios";
import AlertModal from "./AlertModal";

export default function BillingChanges() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [productAdds, setProductAdds] = useState(false);
  const [productsAdds, setProductsAdds] = useState();
  const [isIndexs, setIsIndexs] = useState();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [userFormValue, setUserFormValue] = useState([]);
  console.log("userFormValue", userFormValue);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      userForm: [
        {
          productName: "",
          //   descripTion: "",
          hsd: "",
          UnitPrice: "",
          DiscountPrice: "",
          Quantity: "",
          ActualUnitPrice: "",
          quantityType: "",
        },
      ],
    },
  });
  const { fields, append, prepend, remove } = useFieldArray({
    name: "userForm",
    control,
  });
  const { userForm, gstType } = watch();
  const companyName = sessionStorage.getItem("head");
  const subCompanyName = sessionStorage.getItem("sub");
  const subCompanyList = JSON.parse(subCompanyName);

  const invoiceNumberlist = useQuery("getSalesInvNumber", getLastInvoice);

  let billNum =
    invoiceNumberlist && invoiceNumberlist?.data
      ? invoiceNumberlist && invoiceNumberlist?.data
      : "";

  useEffect(() => {
    if (state === null) {
      setValue("invoiceEntry", billNum + 1);
    }
  }, [billNum]);

  // const editMrMrs = state?.customerName?.slice(0, 2);
  const editMrMrs = state?.customerName.split(" ");

  const EditCustomerName = state?.customerName.substring(
    state?.customerName.indexOf(" ") + 1
  );
  // console.log("editMrMrs", editMrMrs[0], EditCustomerName);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/sales/add`,
          postData
        );
        setOpen("Sales record has been successfully added.");
        return response.data; // Ensure you return the response data so it can be accessed in onSuccess
      } catch (error) {
        console.error("Error in mutation:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        throw error;
      }
    }
  );

  const postFormData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/sales/update/${state.id}`,
        postData
      ),
    {
      onSuccess: () => {
        // alert("Update successful!");
        setUpdateOpen("Sales record has been successfully updated.");
        // navigate("/BillingView");
      },
      onError: (error) => {
        // console.error("Mutation failed:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
      },
    }
  );

  const { mrMrs, customerName, transactionMode } = watch();
  // console.log("gstPercentage",gstPercentage);

  console.log("watch", transactionMode);

  const onSubmit = (data) => {
    console.log("data", data);
    // console.log(
    //   "data?.mrMrs",
    //   `${(data?.mrMrs === undefined ? "" : data?.mrMrs)}${data?.customerName}`
    // );
    const value = {
      customerName: `${
        mrMrs === undefined ? "" : mrMrs ? `${mrMrs}` : ""
      }${" "}${customerName}`,
      customerAddress: data?.customerAddress,
      pincode: data?.pincode,
      contactNumber: data?.contactNumber,
      salesDate: data?.salesDate,
      transactionMode: data?.transactionMode,
      transactionStatus: data?.transactionStatus,
      headOffice: data?.headOffice,
      subOffice: data?.subOffice ? data?.subOffice : null,
      disTrict: data?.district,
      billType: data?.billType,
      gst: data?.gst,
      totalProductAmount: data?.totalProductAmount,
      totalBillAmount: data?.totalBillAmount,
      totalGstAmount: data?.totalGstAmount,
      // gstType: data?.gstType,
      // gstPercentage: data?.gstPercentage,
      invoiceEntry: data?.invoiceEntry,
      userForm,
    };
    console.log(" OnSubimt", value);
    if (state && state?.id) {
      console.log("state?.id", value);
      sessionStorage.setItem("salesInvoice", JSON.stringify(value));
      postFormData.mutate(value);
    } else {
      console.log("BillingData", value);
      sessionStorage.setItem("salesInvoice", JSON.stringify(value));

      mutate(value);
    }
  };
  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );
  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];
  const subOfficeName = details?.filter((item) => {
    return item?.companyType === "subOffice";
  });
  const { data } = useQuery("getProductDetail", getProductDetail);
  const detail = data && data?.data;
  const filterProductName = detail?.filter(
    (item) => item.stock !== "OutOfstock"
  );
  console.log("filterProductName", detail);

  console.log("userForm", userForm);
  // let totalBillAmt = 0;
  // useEffect(() => {
  //   userForm?.map((item, index) => {

  //     totalBillAmt = totalBillAmt + Number(item?.actualPrice)
  //     return setValue("totalBillAmount",totalBillAmt );
  //   });
  // }, [state, append, userForm]);
  // console.log("totalBillAmt", totalBillAmt);

  useEffect(() => {
    if (state) {
      console.log("state?.userForm", state);
      setValue("mrMrs", editMrMrs ? editMrMrs[0] : "");
      setValue("customerName", EditCustomerName);
      setValue("contactNumber", state?.contactNumber);
      setValue("customerAddress", state?.customerAddress);
      setValue("pincode", state?.pincode);
      setValue("transactionMode", state?.transactionMode);
      setValue("transactionStatus", state?.transactionStatus);
      setValue("salesDate", state?.salesDate);
      setValue("headOffice", state?.headOffice);
      setValue("subOffice", state?.subOffice);
      setValue("district", state?.disTrict);
      setValue("billType", state?.billType);
      setValue("invoiceEntry", state?.invoiceEntry);
      setValue("gst", state?.gst);
      // setValue("gstType", state?.gstType);
      // setValue("gstPercentage", state?.gstPercentage);
      setUserFormValue(state?.userForm);
    } else {
      setValue("headOffice", companyName || subCompanyList?.headOff);
      setValue("subOffice", subCompanyList?.subOff);
    }
  }, [state, setValue, append, remove]);

  useEffect(() => {
    if (state?.userForm) {
      remove();
      console.log("KKFFGGGLS", state?.userForm);
      let test;
      // let test=state?.userForm.slice(0,-1)

      userFormValue.forEach((formItem, index) => {
        console.log("LL<<<", userFormValue);
        append({
          productName: formItem.productName,
          unitPrice: formItem.unitPrice,
          discountPrice: formItem.discountPrice,
          quantity: formItem.quantity,
          actualPrice: formItem && formItem?.actualPrice,
          descripTion: formItem.descripTion,
          hsd: formItem.hsd,
          quantityType: formItem.quantityType,
          productAmt: Number(formItem.productAmt),
          gstPercentage: Number(formItem.gstPercentage),
          gstAmount: Number(formItem.gstAmount),
        });
        if (index === 0) {
          setValue(
            `userForm.${0}.productName`,
            userFormValue[index].productName
          );

          setValue(
            `userForm.${index}.unitPrice`,
            userFormValue[index].unitPrice
          );
          setValue(
            `userForm.${index}.discountPrice`,
            userFormValue[index].discountPrice
          );
          setValue(
            `userForm.${index}.productAmt`,
            Number(userFormValue[index].productAmt)
          );
          setValue(
            `userForm.${index}.gstPercentage`,
            Number(userFormValue[index].gstPercentage)
          );
          setValue(
            `userForm.${index}.gstAmount`,
            Number(userFormValue[index].gstAmount)
          );
          setValue(`userForm.${index}.quantity`, userFormValue[index].quantity);
          setValue(
            `userForm.${index}.actualPrice`,
            userFormValue[index].actualPrice
          );
          setValue(
            `userForm.${index}.descripTion`,
            userFormValue[index].descripTion
          );
          setValue(`userForm.${index}.hsd`, userFormValue[index].hsd);
          setValue(
            `userForm.${index}.quantityType`,
            userFormValue[index].quantityType
          );
          setValue(
            `userForm.${index}.gstPercentage`,
            userFormValue[index].gstPercentage
          );
          setValue(
            `userForm.${index}.gstAmount`,
            userFormValue[index].gstAmount
          );
        }
      });
    }
  }, [state, append, userFormValue]);

  // useEffect(() => {
  //   userForm?.forEach((form, index) => {
  //     console.log("indexForm", userForm, index);

  //     setValue(`userForm.${index}.productName`, form?.productName);
  //     setValue(`userForm.${index}.unitPrice`, form?.unitPrice);
  //     setValue(
  //       `userForm.${index}.discountPrice`,
  //       form?.discountPrice
  //     );
  //     setValue(
  //       `userForm.${index}.quantity`,
  //       // userFormValue[index]?.productNameEnglish
  //       //   ?  0
  //       form?.quantity
  //     );
  //     setValue(`userForm.${index}.hsd`, form?.hsd);
  //     setValue(`userForm.${index}.quantityType`, form?.quantityType);
  //     setValue(`userForm.${index}.actualPrice`, form?.actualPrice);

  //     if (index == 0) {
  //       setValue(
  //         `userForm.${0}.productName`,
  //         state
  //           ? state?.userForm[index]?.productName
  //           : userForm[index]?.productName
  //       );

  //       setValue(
  //         `userForm.${index}.unitPrice`,
  //         state?.userForm[index].unitPrice
  //       );
  //       setValue(
  //         `userForm.${index}.discountPrice`,
  //         state?.userForm[index].discountPrice
  //       );
  //       setValue(
  //         `userForm.${index}.quantity`,
  //         state?.userForm[index]?.quantity
  //       );
  //       // setValue(`userForm.${index}.actualPrice`, userForm[index].actualPrice);
  //       state && state ? setValue(`userForm.${index}.actualPrice`, state?.userForm[index].actualPrice) : setValue(`userForm.${index}.actualPrice`, userForm[index].actualPrice)
  //       setValue(`userForm.${index}.hsd`, state?.userForm[index].hsd);
  //       state && state ? setValue(
  //         `userForm.${index}.quantityType`,
  //         state?.userForm[index].quantityType
  //       )
  //         :
  //         setValue(
  //           `userForm.${index}.quantityType`,
  //           userForm[index].quantityType
  //         );
  //     }
  //   });
  // }, [remove, userForm, state]);

  useEffect(() => {
    userFormValue?.forEach((form, index) => {
      console.log("indexForm", userForm, index);
      setValue(`userForm.${index}.unitPrice`, userFormValue[index]?.unitPrice);
      setValue(
        `userForm.${index}.quantityType`,
        userFormValue[index]?.quantityType
      );
      setValue(
        `userForm.${index}.gstPercentage`,
        userFormValue[index]?.gstPercentage
      );
      // setValue(
      //   `userForm.${index}.actualPrice`,
      //   userFormValue[index]?.actualPrice
      // );
      // setValue(
      //   `userForm.${index}.quantity`,
      //   userFormValue[index]?.quantity
      // );
    });
  }, [userFormValue]);
  const removeAppendIndex = (index) => {
    remove(index);
    const array = userFormValue.filter(
      (item, userFormValueIndex) => userFormValueIndex !== index
    );
    console.log("array", array);
    setUserFormValue(array);
  };

  const productDetails = userForm.map((data, index) => {
    return data.productName;
  });
  let max;
  let ins;
  if (productDetails) {
    productDetails.forEach((e, i) => {
      if (e) {
        max = e;
        ins = i;
      }
    });
  }
  const branchAPI = async ({ params }) => {
    console.log("params?.getProductName", params?.getProductName);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/products/products/${params?.getProductName}`
      );
      const responseValue = response && {
        productName: response?.data.productNameEnglish,
        unitPrice: response?.data.unitPrice,
        quantityType: response?.data.quantityType,
        gstPercentage: response?.data.gstPercentage,
        // actualPrice:"",
        // quantity:''
      };
      setUserFormValue((prevProducts) => {
        const updatedProducts = [...userFormValue];
        updatedProducts[isIndexs] = responseValue;
        return updatedProducts;
      });
      // setUserFormValue([...userFormValue, responseValue]);
      // console.log("response.data", responseValue);

      return responseValue;
    } catch (error) {}
  };
  const productList = useQuery(
    ["user", max, ins],
    () => branchAPI({ params: { getProductName: max, index: ins } }),
    {
      enabled: shouldFetch,
      onSettled: () => setShouldFetch(false),
      onSuccess: (data) => {
        console.log("DATA_LOGINSUCESS", data);
        console.log();
        // setShouldFetch(false);
      },
      onError: (data) => {
        console.log("onError", data);
        // setShouldFetch(false);
      },
    }
  );
  const getSalesDetail = async () => {
    return await axios.get(`${process.env.REACT_APP_HOST}/sales/all`);
  };
  const value = useQuery("data", getSalesDetail);

  const { contactNumber } = watch();
  let demoValue = value && value?.data ? value && value?.data?.data : [];
  console.log("demoValue", contactNumber);

  useEffect(() => {
    const checkCustomer =
      demoValue &&
      demoValue?.filter((data) => {
        return Number(data?.contactNumber) === Number(contactNumber);
      });
    console.log("checkCustomer", checkCustomer, contactNumber);

    const checkMrMrs =
      checkCustomer && checkCustomer[0]?.customerName.split(" ");
    const checkCustomerName =
      checkCustomer &&
      checkCustomer[0]?.customerName.substring(
        checkCustomer && checkCustomer[0]?.customerName.indexOf(" ") + 1
      );
    if (checkCustomer.length !== 0) {
      setValue("mrMrs", checkMrMrs ? checkMrMrs[0] : "");
      setValue("customerName", checkCustomerName);
      setValue("contactNumber", checkCustomer[0]?.contactNumber);
      setValue("customerAddress", checkCustomer[0]?.customerAddress);
      setValue("pincode", checkCustomer[0]?.pincode);
      // setValue("salesDate", checkCustomer[0]?.salesDate);
      setValue("district", checkCustomer[0]?.disTrict);
    }
  }, [contactNumber]);

  const { totalProductAmount } = watch();
  console.log("totalProductAmount", totalProductAmount);
  let totalBillAmount = 0;
  let gst = 0;
  let subTotalAmt = 0;
  let totalProductAmt = 0;

  const calculateQuantity = (index) => {
    // const updatedProducts = [...branchValue];
    // const totalBillAmount = userForm[index]?.actualPrice || 0;
    const quantity = userForm[index]?.quantity || 0;
    const unitPrice = userForm[index]?.unitPrice || 0;
    const gstPercentage = userForm[index]?.gstPercentage || 0;
    const discountPrice = userForm[index]?.discountPrice || 0;
    const subTotal =
      // Number(quantity) * Number(unitPrice) -  Number(discountPrice);
      Number(quantity) * Number(unitPrice);
    const gstCalclucation = (subTotal * Number(gstPercentage)) / 100;
    console.log("subTotal", subTotal);
    subTotalAmt = Number(quantity) * Number(unitPrice);
    const totalPrice = subTotal + gstCalclucation - Number(discountPrice);
    // totalProductAmt = subTotal
    if (gstCalclucation > 0) {
      setValue(`userForm.${index}.gstAmount`, gstCalclucation.toString());
    } else if ((gstCalclucation === 0 || gstCalclucation) && state === null) {
      setValue(`userForm.${index}.gstAmount`, 0);
    }
    if (subTotal > 0) {
      console.log("subTotal", subTotal);
      setValue(`userForm.${index}.actualPrice`, totalPrice.toString());
      setValue(`userForm.${index}.productAmt`, subTotal.toString());
      // setValue(`userForm.${index}.totalProductAmount`, totalPrice.toString());
    } else if ((subTotal === 0 || subTotal) && state === null) {
      setValue(`userForm.${index}.actualPrice`, 0);
      setValue(`userForm.${index}.productAmt`, 0);
    }
    if (quantity < 0) {
      setValue(`userForm.${index}.quantity`, 0);
    }
  };

  useEffect(() => {
    userFormValue.map((value, index) => {
      const quantity = userForm[index]?.quantity || 0;
      const unitPrice = userForm[index]?.unitPrice || 0;
      const productAmount = userForm[index]?.productAmt || 0;
      const gstPercentage = userForm[index]?.gstPercentage || 0;
      const discountPrice = userForm[index]?.discountPrice || 0;
      const subTotal =
        // Number(quantity) * Number(unitPrice) -  Number(discountPrice);
        Number(quantity) * Number(unitPrice);
      const gstCalclucation = (subTotal * Number(gstPercentage)) / 100;
      console.log("subTotal", subTotal);
      subTotalAmt = Number(quantity) * Number(unitPrice);
      const totalPrice = subTotal + gstCalclucation - Number(discountPrice);
      // totalProductAmt = subTotal
      if (gstCalclucation > 0) {
        setValue(`userForm.${index}.gstAmount`, gstCalclucation.toString());
      } else if ((gstCalclucation === 0 || gstCalclucation) && state === null) {
        setValue(`userForm.${index}.gstAmount`, 0);
      }
      if (subTotal > 0) {
        console.log("subTotal", subTotal);
        setValue(`userForm.${index}.actualPrice`, totalPrice.toString());
        setValue(`userForm.${index}.productAmt`, subTotal.toString());
        // setValue(`userForm.${index}.totalProductAmount`, totalPrice.toString());
      } else if ((subTotal === 0 || subTotal) && state === null) {
        setValue(`userForm.${index}.actualPrice`, 0);
        setValue(`userForm.${index}.productAmt`, 0);
      }
      if (quantity < 0) {
        setValue(`userForm.${index}.quantity`, 0);
      }
    });
  }, [userFormValue, state]);
  console.log("billNum", isIndexs);

  return (
    <>
      <div className="billing-container" style={{ width: "" }}>
        <div className="BillingAddHeader">
          <div className="Billing-Add-GoBackBtn">
            <img src={Goback} alt="no-image" onClick={() => navigate(-1)} />
          </div>

          <div className="billing-heading-viewblock">
            <div>
              <h3 className="Billng-Add-Heading">Sales Add</h3>
            </div>

            <div className="Billing-Add-ViewBlock">
              <button
                className="Billing-Add-ViewBtn"
                onClick={() => {
                  navigate("/BillingView");
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
        <div
          className="BillingAddMainBlock"
          style={{ border: "none", overflow: "hidden" }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="BillingAddFormBlock"
          >
            <div className="BillingAddInputContainer">
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">Mr/Mrs</label>
                <Controller
                  control={control}
                  name="mrMrs"
                  // defaultValue="Mr"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className="billing_input_field"
                      placeholder="Select"
                      style={{
                        "text-transform": "capitalize",
                      }}
                    >
                      {/* <option value=" ">None</option> */}
                      <option value="Miss">Miss</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                    </select>
                  )}
                />
              </div>

              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">
                  Customer Name{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="customerName"
                  render={(field) => (
                    <BillingAddInputField {...field} type="text" />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">
                  Phone Number
                  {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                </label>
                <Controller
                  control={control}
                  name="contactNumber"
                  render={(field) => (
                    <BillingAddInputField {...field} type="text" />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">
                  Customer Address
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="customerAddress"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <textarea
                      style={error ? { borderColor: "red" } : {}}
                      value={value}
                      onChange={onChange}
                      // onFocus={() => setIsFocused(true)}
                      // onBlur={() => setIsFocused(false)}
                      className="billing_input_field_select"
                    ></textarea>
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">District/State </label>
                <Controller
                  control={control}
                  name="district"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      // placeholder="603103"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">Pincode</label>
                <Controller
                  control={control}
                  name="pincode"
                  render={(field) => (
                    <BillingAddInputField {...field} type="number" />
                  )}
                />
              </div>

              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">
                  Bill Type
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="billType"
                  defaultValue="Tax Invoice"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className="text-capitalize billing_input_field"
                      placeholder="Select"
                      helperText={error ? "This field is required" : ""}
                      // style={{
                      //   "text-transform": "capitalize",
                      // }}
                      style={error ? { borderColor: "red" } : {}}
                    >
                      {/* <option value=""></option> */}
                      <option value="Tax Invoice">Tax Invoice</option>
                      <option value="Quotation">Quotation</option>
                      <option value="Proforma Invoice">Proforma Invoice</option>
                      {error ? "This field is required" : ""}
                    </select>
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">GST No</label>
                <Controller
                  control={control}
                  name="gst"
                  render={(field) => (
                    <BillingAddInputField {...field} type="text" />
                  )}
                />
              </div>
              {/* <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">GST Type</label>
                <Controller
                  control={control}
                  name="gstType"
                  defaultValue="national"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className="billing_input_field"
                      placeholder="Select"
                      style={{
                        "text-transform": "capitalize",
                      }}
                    >
                      <option value="national">national</option>
                      <option value="international">international</option>
                    </select>
                  )}
                />
              </div> */}
              {/* {gstType ? (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">
                    GST %{" "}
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="gstPercentage"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <input
                        type="text"
                        value={value}
                        onChange={onChange}
                        className="text-capitalize billing_input_field"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              ) : (
                ""
              )} */}
              <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">
                  Bill No
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="invoiceEntry"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      className="text-capitalize billing_input_field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div
                className="BillingAddInputField"
                style={{ paddingRight: "12px" }}
              >
                <label className="BillingAddInputLabel">Sales Date</label>
                <Controller
                  control={control}
                  name="salesDate"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <DatePicker
                      className="billing_input_field "
                      {...field}
                      showYearDropdown
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                    />
                  )}
                />
              </div>

              {(companyName || subCompanyName) && (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Head Office</label>
                  <Controller
                    control={control}
                    name="headOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="text"
                        className="billing_input_field"
                        value={value}
                      />
                    )}
                  />
                </div>
              )}
              {subOfficeName.length == 0 ? (
                <></>
              ) : companyName ? (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Branch Office</label>
                  <Controller
                    control={control}
                    name="subOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="billing_input_field"
                        // placeholder="Select"
                        style={{
                          "text-transform": "capitalize",
                        }}
                      >
                        <option value="">Select Branch</option>
                        {subOfficeName?.map((data, index) => (
                          <option key={index} value={data?.shopName}>
                            {data.shopName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              ) : (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Branch Office</label>
                  <Controller
                    control={control}
                    name="subOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="text"
                        className="billing_input_field"
                        value={value}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            {fields.map((field, index) => {
              totalBillAmount = !isNaN(userForm[index].actualPrice)
                ? Number(userForm[index].actualPrice) + Number(totalBillAmount)
                : Number(totalBillAmount);
              gst = !isNaN(userForm[index].gstAmount)
                ? Number(userForm[index].gstAmount) + Number(gst)
                : Number(gst);

              totalProductAmt = !isNaN(userForm[index]?.productAmt)
                ? Number(userForm[index]?.productAmt) + Number(totalProductAmt)
                : Number(totalProductAmt);

              let addSalesValues =
                Number(userForm && userForm[index]?.UnitPrice) *
                  Number(userForm[index]?.Quantity) -
                Number(userForm[index]?.DiscountPrice);
              userForm[index].ActualUnitPrice = addSalesValues;
              console.log("totalBillAmount", field);
              return (
                <div className="d-flex flex-warp">
                  <div className="BillingAddAppendField">
                    <div className="BillingAddAppendInputContainer">
                      <div>
                        <label className="BillingAddInputLabels">
                          Product
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.productName`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              value={value && value ? value : ""}
                              onChange={(event, newValue) => {
                                setShouldFetch(true);
                                setIsIndexs(index);
                                onChange(newValue);
                              }}
                              disabled={
                                filterProductName?.some(
                                  (product) =>
                                    product.productNameEnglish === value
                                ) &&
                                state &&
                                state?.id
                              }
                              className="purchaseSelect_input_field css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                              disablePortal
                              id="combo-box-demo"
                              options={
                                filterProductName
                                  ? filterProductName.map((data) => {
                                      return data?.productNameEnglish;
                                    })
                                  : []
                              }
                              sx={{ height: 40 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={value && value ? value : "none"}
                                  onChange={(e) => onChange(e.target.value)}
                                  error={!!error}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className=" BillingAddInputLabels">Services</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.descripTion`}
                        // name="customerAddress"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <textarea
                            value={value}
                            onChange={onChange}
                            // {...field}
                            className="billing_input_field"
                          ></textarea>
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels ">HSN CD</label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.hsd`}
                        render={({ field: { onChange, value } }) => (
                          <input
                            {...field}
                            className="billing_input_field"
                            type="text"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels ">
                        Quantity
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.quantity`}
                        // name="quantity"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            {...field}
                            style={error ? { borderColor: "red" } : {}}
                            // placeholder="Quantity"
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Quantity Type
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.quantityType`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <select
                            value={value}
                            className="ps-0 billing_input_field "
                            onChange={onChange}
                            style={error ? { borderColor: "red" } : {}}
                          >
                            <option value=""> </option>
                            {[
                              ...new Set(
                                detail?.map((data) => data.quantityType)
                              ),
                            ].map((quantityType, index) => (
                              <option key={index} value={quantityType}>
                                {quantityType}
                              </option>
                            ))}
                          </select>
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Unit Price
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        // name="unitPrice"
                        name={`userForm.${index}.unitPrice`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            style={error ? { borderColor: "red" } : {}}
                            {...field}
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Product Amount
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        // name="unitPrice"
                        name={`userForm.${index}.productAmt`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            style={error ? { borderColor: "red" } : {}}
                            {...field}
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        GST Percentage
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        // name="unitPrice"
                        name={`userForm.${index}.gstPercentage`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            style={error ? { borderColor: "red" } : {}}
                            {...field}
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        GST Amount
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        control={control}
                        // name="unitPrice"
                        name={`userForm.${index}.gstAmount`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <input
                            readOnly
                            style={error ? { borderColor: "red" } : {}}
                            {...field}
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Discount Price
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.discountPrice`}
                        className="discount"
                        render={({ field: { onChange, value } }) => (
                          <input
                            {...field}
                            // placeholder="Discount Price"
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="BillingAddAppendInputContainer">
                      <label className="BillingAddInputLabels">
                        Actual Unit Price
                      </label>
                      <Controller
                        control={control}
                        name={`userForm.${index}.actualPrice`}
                        defaultValue={addSalesValues}
                        render={({ field: { onChange, value } }) => (
                          <input
                            {...field}
                            className="billing_input_field"
                            type="number"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              calculateQuantity(index);
                            }}
                          />
                        )}
                      />
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ width: "1%" }}
                    >
                      {index !== 0 ? (
                        <img
                          style={{ width: "35px", height: "45px" }}
                          src={deleteIcon}
                          alt="DELETE"
                          //   {...register(`userForm.${index}.image`)}
                          onClick={() => removeAppendIndex(index)}
                        />
                      ) : (
                        <></>
                      )}
                      <div>
                        {index < 0 ? (
                          <></>
                        ) : (
                          <>
                            <div
                              style={{
                                height: "100px",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "10px",
                              }}
                            >
                              <img
                                src={Add}
                                style={{ width: "40px", height: "45px" }}
                                alt=""
                                className=" BillingAdd-AddBtn"
                                onClick={() => {
                                  append({
                                    // Product: " ",
                                    // UnitPrice: 0,
                                    // DiscountPrice: 0,
                                    // Quantity: 0,
                                    // ActualUnitPrice: 0,
                                    // quantityType: " ",
                                  });
                                }}
                              ></img>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="d-flex ">
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">
                  Transaction Mode
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="transactionMode"
                  defaultValue="Online"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className="billing_input_field"
                      style={error ? { borderColor: "red" } : {}}
                    >
                      {/* <option value=""></option> */}
                      <option value="Cash">Cash</option>
                      <option value="Online">Online</option>
                      <option value="credit">credit</option>
                    </select>
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">
                  Transaction Status
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="transactionStatus"
                  defaultValue="Completed"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className=" text-capitalize billing_input_field"
                      placeholder="Select"
                      style={error ? { borderColor: "red" } : {}}
                    >
                      <option value="Completed">completed</option>
                      <option value="Pending">pending</option>
                    </select>
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">Bill Amount</label>
                <Controller
                  control={control}
                  name="totalProductAmount"
                  render={(field) => (
                    <input
                      className="  billing_input_field"
                      {...field}
                      // value={field?.value}
                      // readOnly
                      type="number"
                      value={totalProductAmt}
                    />
                  )}
                />
              </div>
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">GST Amount %</label>
                <Controller
                  control={control}
                  name="totalGstAmount"
                  render={(field) => (
                    <input
                      className="  billing_input_field"
                      {...field}
                      readOnly
                      type="number"
                      value={gst}
                    />
                  )}
                />
              </div>

              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">
                  Total Gross Amount
                  {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                </label>
                <Controller
                  control={control}
                  name="totalBillAmount"
                  render={(field) => (
                    <input
                      className="  billing_input_field"
                      {...field}
                      readOnly
                      type="number"
                      value={totalBillAmount}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="ms-3 BillingAddSubmitBlock"
              style={{ display: "flex", gap: "10px" }}
            >
              <div>
                <button type="submit" className="BillingAddSubmitBtn">
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "save"
                  )}
                </button>
              </div>
              <></>
              <div>
                <button
                  type="btn"
                  className="BillingAddSubmitBtn"
                  onClick={() => setProductAdds(true)}
                >
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "View Print"
                  )}
                </button>
              </div>
            </div>
            {/* <div className="ms-3 BillingAddSubmitBlock">
              <div>
                <button type="submit" className="BillingAddSubmitBtn">
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        val={productAdds ? productsAdds : ""}
        navigate={productAdds ? "/BillingInvoice" : "/BillingView"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </>
  );
}
