import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import Goback from "../assets/image/GoBack.svg";
import vinmar from "../assets/image/vinmarPools.jpg";
import moment from "moment";
import { getCustomerRegister, headOfficeName } from "../api/GlobalApi";
// import { useQuery } from "react-query";
import { useQuery } from "react-query";
import numWords from "num-words";

const VinmarPoolInvoice = () => {
  const [words, setWords] = useState();
  const componentDownLoadRef = useRef();
  const componentPrintRef = useRef();
  // const ShareRef = useRef();
  const [sessionValue, setSessionValue] = useState([]);

  // console.log("words", words);

  const user = sessionStorage.getItem("data");
  console.log("sessionValue", sessionValue);
  let name;
  let totalPrice = 0;
  let gst = 0;
  let grandTotal = 0;
  let rateTotal = 0;
  let productTotal = 0;
  let options;
  let formattedGrandTotal = 0;
  let formattedRatePrice = 0;
  let formattedProductTotal = 0;
  let formattedGst = 0;
  let totalGst = 0;
  // let numWords = require("num-words");
  // let amountInWords = numWords(words);
  // console.log('amountInWords',amountInWords);

  console.log("formattedGst", formattedGst);
  console.log("data", name);
  console.log("img", user);
  useEffect(() => {
    const savedObjectString = sessionStorage.getItem("salesInvoice");
    if (savedObjectString) {
      setSessionValue(JSON.parse(savedObjectString));
      // console.log("savedObjectString", sessionValue);
    }
  }, []);

  const [isRemove, setIsRemove] = useState(false);
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
    documentTitle: "TAX INVOICE",
    // onBeforePrint:()=>alert('printing is going on'),
    // onAfterPrint:()=>alert('printing success')
  });

  // console.log("ses",sessionValue[0  ]?.actualPrice);
  const handleShare = () => {
    const shareInvoice = componentDownLoadRef.current;

    html2canvas(shareInvoice).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 5;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`Delivery ${sessionValue?.billType}.pdf`);
    });
  };
  useEffect(() => {
    if (isRemove === true) {
      sessionStorage.removeItem("myValue");
      // navigate("/Billing/BillingTable");
    }
  }, [isRemove]);
  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );
  const getHeadOffice = useQuery("headOfficeName", headOfficeName);
  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];

  let headOfficeList =
    getHeadOffice && getHeadOffice?.data && getHeadOffice?.data?.data
      ? getHeadOffice && getHeadOffice?.data && getHeadOffice?.data?.data
      : [];

  const subOfficeName = details?.filter((item) => {
    return sessionValue?.subOffice === item?.shopName;
  });

  console.log("subOfficeName", subOfficeName);

  const headOffice = details?.filter((item) => {
    return sessionValue?.headOffice === item?.shopName;
  });
  console.log("subOfficeName", subOfficeName);
  const SubHeadOffice = headOfficeList?.filter((item) => {
    return subOfficeName[0]?.headoffice === item.shopName;
  });

  const [text, setText] = useState(null);

  function capitalizeFirstLetter(string) {
    return (
      string &&
      string
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  }
  
  return (
    <section>
      <div className="container  ml-5">
        <div className="billing_invoice_header py-3 ">
          <div>
            <img src={Goback} alt="go back" onClick={() => navigate(-1)} />
          </div>

          <div className="invoice_head_section">
            <div>
              <h3 className="billing_invoice_heading">Sales Invoice</h3>
            </div>

            <div>
              <input
                type="submit"
                value="Print"
                className="billing_print_btn"
                onClick={() => (handlePrint(), setIsRemove(!isRemove))}
              />
              <input
                type="submit"
                value="Download"
                className="billing_print_btn"
                onClick={() => (handleShare(), setIsRemove(!isRemove))}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="container d-flex flex-column align-items-center justify-content-center "
          ref={componentDownLoadRef}
        >
          <div
            className="border border-2 border-black print-container"
            // className="print-container"
            style={{ height: "", width: "100%" ,marginX:"8px" }}
            ref={componentPrintRef}
          >
            <header className="">
              <div
                style={{ backgroundColor: "lightgray" }}
                className="border-bottom border-2 border-black py-2 text-center fs-4 fw-bold"
              >
                VINMAR POOLS
              </div>

              <div className="border-bottom border-2 border-black py-1 px-2 text-center fs-6 fw-bold d-flex justify-content-between ">
                <p className="mb-0">Debit Memo</p>
                <p className="mb-0 fs-5 fw-bold">{sessionValue?.billType}</p>
                <p className="mb-0">Original</p>
              </div>
              <div className="row border-bottom border-1 border-black m-0">
                <div
                  style={{ fontSize: "0px", fontWeight: "500" }}
                  className=" col-9  py-1  border-end border-1 border-black py-3 d-flex "
                >
                  <div>
                    {sessionValue?.subOffice !== null && (
                      <p className="mb-1 fs-6 fw-bold">Head Office :</p>
                    )}
                    <p className="mb-0 fs-2 fw-bold">
                      {(headOffice[0] || SubHeadOffice[0])?.shopName}{" "}
                    </p>
                    <p className="mb-0 fs-5">
                      {" "}
                      {(headOffice[0] || SubHeadOffice[0])?.companyAddress}{" "}
                    </p>
                    {/* <p className="mb-0 "> Kancheepuram, </p> */}
                    <p className="mb-0 fs-5">
                      {(headOffice[0] || SubHeadOffice[0])?.district} -{" "}
                      {(headOffice[0] || SubHeadOffice[0])?.companyPincode}{" "}
                    </p>

                    <div className="vinmar_address fs-5 d-flex">
                      <div style={{ width: "80px" }}>GST NO</div> :{" "}
                      {(headOffice[0] || SubHeadOffice[0])?.gstNumber}
                    </div>
                    <div className="vinmar_address fs-5 d-flex">
                      <div style={{ width: "80px" }}>CONTACT</div> :{" "}
                      {(headOffice[0] || SubHeadOffice[0])?.customerPhno}
                    </div>
                    <div className="vinmar_address fs-5 d-flex">
                      <div style={{ width: "80px" }}>E-MAIL</div> :{" "}
                      <span
                        style={{
                          color: "blue",
                          fontWeight: "600",
                          paddingLeft: "6px",
                        }}
                      >
                        {" "}
                        {(headOffice[0] || SubHeadOffice[0])?.emailId}
                      </span>
                    </div>
                    <div className="vinmar_address fs-5 d-flex">
                      <div style={{ width: "80px" }}>WEBSITE</div> :{" "}
                      <span
                        style={{
                          color: "blue",
                          fontWeight: "600",
                          paddingLeft: "6px",
                        }}
                      >
                        {" "}
                        {(headOffice[0] || SubHeadOffice[0])?.website}
                      </span>
                    </div>
                  </div>
                  {sessionValue?.subOffice !== null && (
                    <div className="ps-3">
                      <p className="mb-1 fs-4 fw-bold">Branch Office :</p>
                      <p className="mb-0 fs-2 fw-bold">
                        {" "}
                        {subOfficeName[0]?.shopName}
                      </p>
                      <p className="mb-0 fs-5">
                        {" "}
                        {subOfficeName[0]?.companyAddress}
                      </p>
                      {/* <p className="mb-0 "> Kancheepuram, </p> */}
                      <p className="mb-0 fs-5">
                        {subOfficeName[0]?.district} -{" "}
                        {subOfficeName[0]?.companyPincode}
                      </p>

                      {/* <p className="mb-0 fs-6">
                        <span className=" fw-bold ">GST No. </span>
                        {subOfficeName[0]?.gstNumber}
                      </p>
                      <p className="mb-0 fs-6">
                        <span className=" fw-bold">E-MAIL : </span>
                        {subOfficeName[0]?.emailId}
                      </p>
                      <p className="mb-0  fs-6">
                        <span className=" fw-bold">MOB NO : </span>
                        {subOfficeName[0]?.customerPhno}
                      </p>
                      <p className="mb-0  fs-6">
                        <span className=" fw-bold">WEB SITE : </span>
                        {subOfficeName[0]?.website}
                      </p> */}
                      <div className="vinmar_address d-flex fs-5">
                        <div style={{ width: "80px" }}>GST NO</div> :{" "}
                        {subOfficeName[0]?.gstNumber}
                      </div>
                      <div className="vinmar_address d-flex fs-5">
                        <div style={{ width: "80px" }}>CONTACT</div> :{" "}
                        {subOfficeName[0]?.emailId}
                      </div>
                      <div className="vinmar_address d-flex fs-5">
                        <div style={{ width: "80px" }}>E-MAIL</div> :{" "}
                        <span
                          style={{
                            color: "blue",
                            fontWeight: "600",
                            paddingLeft: "6px",
                          }}
                        >
                          {" "}
                          {subOfficeName[0]?.customerPhno}
                        </span>
                      </div>
                      <div className="vinmar_address d-flex fs-5">
                        <div style={{ width: "80px" }}>WEBSITE</div> :{" "}
                        <span
                          style={{
                            color: "blue",
                            fontWeight: "600",
                            paddingLeft: "6px",
                          }}
                        >
                          {" "}
                          {subOfficeName[0]?.website}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-3  p-0 d-flex flex-column justify-content-between">
                  <div
                    style={{ height: "180px" }}
                    className=" d-flex justify-content-center align-items-center"
                  >
                    <img
                      style={{
                        width: "200px",
                        height: "160px",
                        borderRadius: "20px",
                      }}
                      src={`data:image/jpeg;base64,${user}`}
                      alt="logo"
                    />
                  </div>
                  <div
                    style={{ backgroundColor: "lightgray" }}
                    className="px-2 border-top border-2 border-black"
                  >
                    {/* <p className="mb-0  fw-bold">Invoice No : {sessionValue?.id}</p>
                <p className="mb-0  fw-bold">
                  Date : {moment().format("DD/MM/YYYY")}
                </p> */}
                    <table>
                      <tbody>
                        <tr className="fs-5">
                          <th>Invoice No </th>
                          <th>
                            : {sessionValue?.invoiceEntry}/
                            {moment().format("YYYY")}
                          </th>
                        </tr>
                        <tr className="fs-5">
                          <th>Date </th>
                          <th>: {moment(sessionValue?.salesDate).format("DD/MM/YYYY")}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="border-bottom border-1 border-black px-2 py-3">
                {/* <p className=" mb-0 fs-5">
                  <span className="fs-5 fw-bold bg-danger" style={{width:"200px"}}>Bill address : </span>
                  {sessionValue?.customerName}, {sessionValue?.customerAddress},{" "}
                  {sessionValue?.disTrict} - {sessionValue?.pincode} <br />
                  <span className="fw-bold">Contact </span> -{" "}
                  {sessionValue?.contactNumber} <br />
                  {sessionValue?.gst && (
                    <p className="mb-0">
                      {" "}
                      <span className="fw-bold "> GST No - </span>{" "}
                      {sessionValue?.gst}{" "}
                    </p>
                  )}
                </p> */}
                <div className=" mb-0 fs-5">
                  <div className="d-flex">
                    <div
                      className="fs-5 fw-bold"
                      style={{ width: "120px" }}
                    >
                      Bill address
                    </div>
                    <div>
                     : {sessionValue?.customerName},{" "}
                      {sessionValue?.customerAddress}, {sessionValue?.disTrict}{" "}
                      - {sessionValue?.pincode} 
                    </div>
                  </div>
                  <div className="d-flex">
                    <div
                      className="fs-5 fw-bold "
                      style={{ width: "120px" }}
                    >
                      Contact
                    </div>
                    <div>
                     : {sessionValue?.contactNumber}
                    </div>
                  </div>
                  {sessionValue?.gst && <div className="d-flex">
                    <div
                      className="fs-5 fw-bold "
                      style={{ width: "120px" }}
                    >
                      GST NO 
                    </div>
                    <div>
                     : {sessionValue?.gst}
                    </div>
                  </div>}
                </div>
                {/* <span>{sessionValue?.gst}</span> */}
                {/* <p className=" mb-0 fs-5">hello</p> */}
              </div>
            </header>
            <section>
              <div style={{
          // maxHeight: "600px",
          overflowY: "auto",
          border: "1px solid black",
        }}
              >
              <table
                // style={{ width: "100%" ,height:"600px"}}
                style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
          }}
                className="border-bottom border-black"
              >
                <thead className="border-bottom  border-black d-flex">
                  <th
                    style={{ fontSize: "20px", width: "5%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    SrNo
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "45%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    NAME OF THE PRODUCT/SERVICES
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "10%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    QTY
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "10%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    HSN CD
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "7%" }}
                    className="text-center pe-2  fw-bold border-end  border-black"
                  >
                    GST Amount
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "3%" }}
                    className="text-center pe-2  fw-bold border-end  border-black"
                  >
                    %
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "10%" }}
                    className="text-center pe-2 p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    RATE
                  </th>
                  <th
                    style={{ fontSize: "20px", width: "10%" }}
                    className="text-center pe-2 p-1 fw-bold"
                  >
                    {" "}
                    AMOUNT
                  </th>
                </thead>
                {/* <div className="border-bottom  border-black d-flex">
                  <div
                    style={{ fontSize: "14px", width: "5%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    SrNo
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "45%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    NAME OF THE PRODUCT/SERVICES
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "10%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    QTY
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "10%" }}
                    className="text-center p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    HSN CD
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "7%" }}
                    className="text-center pe-2  fw-bold border-end  border-black"
                  >
                    GST Amount
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "3%" }}
                    className="text-center pe-2  fw-bold border-end  border-black"
                  >
                    %
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "10%" }}
                    className="text-center pe-2 p-1 fw-bold border-end  border-black"
                  >
                    {" "}
                    RATE
                  </div>
                  <div
                    style={{ fontSize: "14px", width: "10%" }}
                    className="text-center pe-2 p-1 fw-bold"
                  >
                    {" "}
                    AMOUNT
                  </div>
                </div> */}

                {sessionValue?.userForm?.map((item, i) => {
                  console.log("gstPercentage", item);

                  totalGst = Number(item?.gstAmount) + Number(totalGst);
                  formattedGst = new Intl.NumberFormat("en-IN").format(
                    Number(totalGst)
                  );

                  rateTotal =
                    Number(sessionValue?.userForm[i]?.unitPrice) + rateTotal;
                  formattedRatePrice = new Intl.NumberFormat("en-IN").format(
                    rateTotal
                  );

                  productTotal =
                    item?.actualPrice - item?.gstAmount + productTotal;
                  formattedProductTotal = new Intl.NumberFormat("en-IN").format(
                    productTotal
                  );
                  gst =
                    sessionValue?.gstType === "national"
                      ? ((Number(sessionValue?.gstPercentage) / 2) *
                          totalPrice) /
                        100
                      : (Number(sessionValue?.gstPercentage) * totalPrice) /
                        100;

                  grandTotal = totalGst + productTotal;

                  formattedGrandTotal = new Intl.NumberFormat("en-IN").format(
                    grandTotal
                  );
                  console.log("gstCount", Number(formattedGst).toFixed(0));

                  if (text == null) {
                    setText(numWords(Number(grandTotal).toFixed(0)));
                  }

                  return (
                    <>
                      <tbody className="d-flex" style={{ width: "100%" }} >
                        <td
                          style={{ fontSize: "22px",width: "5%"  }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{ fontSize: "22px", fontWeight: "normal",width: "45%" }}
                          className="p-2 text-start text-break border-end  border-black"
                        >
                          <span className="fw-bold">{item?.productName}/</span>{" "}
                          {item?.descripTion}
                        </td>
                        <td
                          style={{ fontSize: "22px",width: "10%" }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {item?.quantity}/{item?.quantityType}
                        </td>
                        <td
                          style={{ fontSize: "22px",width: "10%"  }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {item?.hsd}
                        </td>
                        <td
                          style={{ fontSize: "22px",paddingRight:"30px", width: "7%" }}
                          className="text-end  py-2  border-end  border-black"
                        >
                          {item?.gstAmount}
                        </td>
                        <td
                          style={{ fontSize: "22px",width: "3%" }}
                          className="text-end pe-2 py-2 border-end  border-black"
                        >
                          {item?.gstPercentage}
                        </td>
                        <td
                          style={{ fontSize: "22px",paddingRight:"40px",width: "10%" }}
                          className="py-2 text-end  border-end  border-black"
                        >
                          {item?.unitPrice}
                        </td>
                        <td
                          style={{ fontSize: "22px",paddingRight:"40px" ,width: "10%"}}
                          className="py-2 text-end  "
                        >
                          {item?.actualPrice - item?.gstAmount}
                        </td>
                      </tbody>
                    </>
                  );
                })}
               

                {/* {sessionValue?.userForm?.map((item, i) => {
                  console.log("gstPercentage", item);

                  totalGst = Number(item?.gstAmount) + Number(totalGst);
                  formattedGst = new Intl.NumberFormat("en-IN").format(
                    Number(totalGst)
                  );

                  rateTotal =
                    Number(sessionValue?.userForm[i]?.unitPrice) + rateTotal;
                  formattedRatePrice = new Intl.NumberFormat("en-IN").format(
                    rateTotal
                  );

                  productTotal =
                    item?.actualPrice - item?.gstAmount + productTotal;
                  formattedProductTotal = new Intl.NumberFormat("en-IN").format(
                    productTotal
                  );
                  gst =
                    sessionValue?.gstType === "national"
                      ? ((Number(sessionValue?.gstPercentage) / 2) *
                          totalPrice) /
                        100
                      : (Number(sessionValue?.gstPercentage) * totalPrice) /
                        100;

                  grandTotal = totalGst + productTotal;

                  formattedGrandTotal = new Intl.NumberFormat("en-IN").format(
                    grandTotal
                  );
                  console.log("gstCount", Number(formattedGst).toFixed(0));

                  if (text == null) {
                    setText(numWords(Number(grandTotal).toFixed(0)));
                  }

                  return (
                    <>
                      <tbody style={{ width: "100%" }}>
                        <td
                          style={{ fontSize: "20px" }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{ fontSize: "20px", fontWeight: "normal" }}
                          className="p-2 text-start text-break border-end  border-black"
                        >
                          <span className="fw-bold">{item?.productName}/</span>{" "}
                          {item?.descripTion}
                        </td>
                        <td
                          style={{ fontSize: "20px" }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {item?.quantity}/{item?.quantityType}
                        </td>
                        <td
                          style={{ fontSize: "20px" }}
                          className="py-2 text-center border-end  border-black"
                        >
                          {item?.hsd}
                        </td>
                        <td
                          style={{ fontSize: "20px",paddingRight:"30px" }}
                          className="text-end    border-end  border-black"
                        >
                          {item?.gstAmount}
                        </td>
                        <td
                          style={{ fontSize: "20px" }}
                          className="text-end pe-2  border-end  border-black"
                        >
                          {item?.gstPercentage}
                        </td>
                        <td
                          style={{ fontSize: "20px",paddingRight:"40px" }}
                          className="py-2 text-end  border-end  border-black"
                        >
                          {item?.unitPrice}
                        </td>
                        <td
                          style={{ fontSize: "20px",paddingRight:"40px" }}
                          className="py-2 text-end  "
                        >
                          {item?.actualPrice - item?.gstAmount}
                        </td>
                      </tbody>
                    </>
                  );
                })} */}
              </table>
              </div>
              <div
                style={{ backgroundColor: "lightgray", height: "50px" }}
                className=" border-bottom border-top border-black d-flex justify-content-end align-items-center"
              >
                <div style={{ width: "40%" }} className="  border-black">
                  <div className="d-flex justify-content-">
                    <div
                      style={{
                        fontSize: "22px",
                        width: "25%",
                        textAlign: "end",
                        paddingRight: "8px",
                        fontWeight:"700"
                      }}
                      className="mb-0   "
                    >
                      Sub Total
                    </div>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight:"700",
                        width: "25%",
                        textAlign: "center",
                        paddingRight: "40px",
                      }}
                      className="mb-0   "
                    >
                      {formattedGst}
                    </div>
                    <div
                      style={{
                        fontSize: "22px",
                        width: "25%",
                        textAlign: "end",
                        paddingRight: "40px",
                        fontWeight:"700"
                      }}
                      className="mb-0   "
                    >
                      {formattedRatePrice}
                    </div>
                    <div
                      style={{
                        fontSize: "22px",
                        width: "25%",
                        textAlign: "end",
                        paddingRight: "40px",
                        fontWeight:"700"
                      }}
                      className="mb-0   "
                    >
                      {formattedProductTotal}
                    </div>
                    {/* <p
                      className="mb-0 fw-bold ps-3 fs-5"
                      style={{ width: "67%" }}
                    >
                      Sub Total
                    </p>

                    <p
                      className="mb-0 fw-bold fs-5  text-center"
                      style={{ width: "34%" }}
                    >
                      {formattedTotalPrice}
                    </p> */}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </section>
            <footer>
              <div className="">
                <div className="d-flex">
                  <div
                    style={{ width: "70%" }}
                    className="border-end border-bottom border-black ps-3 py-3"
                  >
                    <table style={{fontSize:"20px"}} >
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th> Current Acc Holder Name </th>
                          <td>: VINMAR POOLS</td>
                        </tr>
                        <tr>
                          <th> Bank Name </th>
                          <td>: INDIAN OVERSEAS BANK</td>
                        </tr>
                        <tr>
                          <th> A/C No </th>
                          <td>: 282202000000020</td>
                        </tr>
                        <tr>
                          <th> IFSC Code </th>
                          <td>: IOBA0002822</td>
                        </tr>
                        <tr>
                          <th> Branch </th>
                          <td>: KANATHUR REDDYKUPPAM</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{ width: "30%" }}
                    className="pb-3 ps-4 d-flex flex-column justify-content-center"
                  >
                    <div className="d-flex pb-2 " style={{fontSize:"22px"}}>
                      <p className="mb-0 fw-bold " style={{ width: "70%" }}>
                        Total Amount
                      </p>

                      <p
                        className="mb-0 fw-bold  "
                        style={{ width: "30%", textAlign: "end",paddingRight:"40px" }}
                      >
                        {formattedProductTotal}
                      </p>
                    </div>
                    <div className="d-flex pb-2" style={{fontSize:"22px"}}>
                      <p className="mb-0 fw-bold" style={{ width: "70%" }}>
                        Total GST
                      </p>

                      <p
                        className="mb-0 fw-bold "
                        style={{ width: "30%", textAlign: "end",paddingRight:"40px" }}
                      >
                        {formattedGst}
                      </p>
                    </div>
                    {/* {sessionValue?.gstType === "national" ? (
                      <div className=" ">
                        <div className="d-flex justify-content-between ">
                          <p
                            className="mb-0 fw-bold ps-3"
                            style={{ width: "67%" }}
                          >
                            CGST Tax {Number(sessionValue?.gstPercentage) / 2}%
                          </p>
                          {console.log("formattedGst", formattedGst)}

                          <p
                            className="mb-0 fw-bold  text-center"
                            style={{ width: "34%" }}
                          >
                            {formattedGst}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                          <p
                            className="mb-0 fw-bold ps-3"
                            style={{ width: "67%" }}
                          >
                            SGST Tax {Number(sessionValue?.gstPercentage) / 2}%
                          </p>

                          <p
                            className="mb-0 fw-bold  text-center"
                            style={{ width: "34%" }}
                          >
                            {formattedGst}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {sessionValue?.gstType === "international" ? (
                      <div className=" ">
                        <div className="d-flex justify-content-between my-2">
                          <p
                            className="mb-0 fw-bold ps-3"
                            style={{ width: "67%" }}
                          >
                            IGST Tax {Number(sessionValue?.gstPercentage)}%
                          </p>

                          <p
                            className="mb-0 fw-bold  text-center"
                            style={{ width: "34%" }}
                          >
                            {formattedGst}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    style={{ width: "70%", height: "52px",fontSize:"20px" }}
                    className="border-end border-bottom border-black ps-3 py-1"
                  >
                    <i>
                      <span className="fw-bold">Bill Amount : </span>
                      {/* {amountInWords} */}
                      {capitalizeFirstLetter(text && text)}
                    </i>
                  </div>
                  <div
                    style={{ width: "30%" }}
                    className="border-bottom border-black"
                  ></div>
                </div>
                <div className="d-flex">
                  <div
                    style={{ width: "70%",fontSize:"20px" }}
                    className="border-end border-bottom border-black ps-3 py-1"
                  >
                    <p className="mb-0 ">
                      <span className="fw-bold">DECLARATION : </span>
                      We declare that this invoice shows the actual price of the
                      goods Described and that all particulars are true and
                      correct
                    </p>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      height: "70px",
                      backgroundColor: "lightgray",
                      alignContent: "center",
                    }}
                    className=" ps-4 border-bottom border-black"
                  >
                    <div className="d-flex pb-2 " style={{fontSize:"22px"}}>
                      <p className="mb-0 fw-bold " style={{ width: "70%" }}>
                        Grand Total
                      </p>

                      <p
                        className="mb-0 fw-bold  "
                        style={{ width: "30%", textAlign: "end",paddingRight:"40px" }}
                      >
                        {formattedGrandTotal}
                      </p>
                    </div>
                    {/* <div className="d-flex pb-2 py-3">
                      <p
                        className="mb-0 fw-bold ps-3  "
                        style={{ width: "67%" }}
                      >
                        Grand Total
                      </p>

                      <p
                        className="mb-0 fw-bold  text-center"
                        style={{ width: "34%" }}
                      >
                        
                        {formattedGrandTotal}
                        
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="ps-3 d-flex justify-content-between">
                  <div style={{fontSize:"22px"}}>
                    {/* PAYMENT 100% BEFORE DELIVERY	FOR VINMAR POOLS
              :   NOT RESPOSIPLE FOR LOSS AND DAMAGE
              :   MATERIALS ONCE SOLD WILL BE TAKEN. */}

                    <p className="mb-0 fw-bold">Terms & Condition :</p>
                    <i>
                      1. PAYMENT 100% BEFORE DELIVERY FOR VINMAR POOLS
                      <br />
                      2. NOT RESPOSIPLE FOR LOSS AND DAMAGE
                      <br />
                      3.MATERIALS ONCE SOLD WILL BE TAKEN.
                      <br />
                      {/* 4. "Subject to 'RAJKOT' Jurisdiction only. E.&.O.E" */}
                    </i>
                  </div>
                  <div style={{fontSize:"22px"}} className="pe-3 d-flex flex-column justify-content-between">
                    <p className="mb-0">For, Vinmar Pools</p>
                    <p className="mb-0 pb-1">(Authority Signature)</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
          <footer style={{ textAlign: "center", color: "#adadad" }}>
            {/* Developed By <a href="www.2kvirtualworld.com "></a>  */}
            Developed By{" "}
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://www.2kvirtualworld.com"
            >
              www.2kvirtualworld.com
            </a>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default VinmarPoolInvoice;

{
  // actualPrice: 13490;
  // contactNumber: "8569369856";
  // customerAddress: "kelampakkam";
  // customerName: "shalini";
  // discountPrice: 10;
  // id: 3;
  // pincode: "600302";
  // productName: "gi wire model 1";
  // quantity: 15;
  // salesDate: "2024-07-06T00:00:00.000+00:00";
  // total: 13490;
  // transactionMode: "Cash";
  // transactionStatus: "Completed";
  // unitPrice: 900;
}
